const currentUser = `
    query currentUser {
        currentUser {
            email
        }
    }
`;

export default {
  currentUser
};
