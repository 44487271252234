import React from 'react';
import MainLayout from '../components/MainLayout';
import { gql, useMutation } from '@apollo/client';

type Props = {
  children: any;
  currentUser: any;
};

const logout = `
    mutation logout {
        logout
    }
`;

const MainLayoutContainer = (props: Props) => {
  const [logoutMutation, { data }] = useMutation(gql(logout));

  if (data) {
    window.location.reload();
  }

  const onLogout = () => {
    logoutMutation();
  };

  return <MainLayout logout={onLogout} {...props} />;
};

export default MainLayoutContainer;
