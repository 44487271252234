import queryString from "query-string";

/**
 * @param {Object} query
 */
const setParams = (history: any, query: any) => {
  const { location } = history;

  // convert to {param1: value1}
  const parsed = queryString.parse(location.search);

  // convert back to param1=value1&param2=value2
  const stringified = queryString.stringify({ ...parsed, ...query });

  // go to new url
  history.push(`${location.pathname}?${stringified}`);
};

/**
 * @param {String} name
 */
const getParam = (history: any, name: any) => {
  const location = Object.assign({}, history.location);

  // convert to {param1: value1}
  const parsed = queryString.parse(location.search);

  return parsed[name];
};

/**
 * @param {...String} queryNames
 */
const removeParams = (history: any, ...queryNames: any) => {
  const { location } = history;

  // convert to {param1: value1}
  const parsed = queryString.parse(location.search);

  // remove given parameters
  queryNames.forEach((q: any) => delete parsed[q]);

  // convert back to param1=value1&param2=value2
  const stringified = queryString.stringify(parsed);

  // go to new url
  history.push(`${location.pathname}?${stringified}`);
};

export default {
  setParams,
  getParam,
  removeParams,
};
