import { queries } from '../graphql';
import { gql, useQuery } from '@apollo/client';

const useCurrentUser = () => {
  const { data, error, loading } = useQuery(gql(queries.currentUser));

  if (data) {
    return { currentUser: data.currentUser, loading, error };
  }

  return { currentUser: null, loading, error };
};

export default useCurrentUser;
