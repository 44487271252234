import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

const link = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  credentials: 'include'
});

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});
