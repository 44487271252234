import { Button, Card, Form, Input, Select } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Uploader from "../../common/components/Uploader";
// import EditorCK from "../../common/components/EditorCK";

const STATUS = [
  { value: "active", label: "Идэвхтэй" },
  { value: "inactive", label: "Идэвхгүй" },
];

const FormItem = Form.Item;

type Props = {
  save: (doc: any) => void;
  news?: any;
};

const FormComponent = (props: Props) => {
  const [form] = Form.useForm();
  const news = props.news || {};
  const initImage = news.image
    ? { name: news.image.name, url: news.image.url }
    : null;

  const [status, setStatus] = useState(news.status || "active");
  // const [content, setContent] = useState(news.content);
  const [image, setImage] = useState(initImage);

  const onFinish = (values: any) => {
    props.save({ ...values, status, image });
  };

  const onChangeImageUrl = (fileList: any[]) => {
    if (fileList && fileList.length > 0) {
      setImage(fileList[0]);
    } else {
      setImage(null);
    }
  };

  const initialValues = {
    title: news.title,
    description: news.description,
    content: news.content,
    status: news.status || "active",
  };

  const formItemLayout = {
    labelCol: {
      sm: {
        span: 4,
      },
    },
  };

  return (
    <Card title={`Мэдээ ${news._id ? "засах" : "нэмэх"}`}>
      <Form
        {...formItemLayout}
        onFinish={onFinish}
        initialValues={initialValues}
        form={form}
        style={{ maxWidth: "800px" }}
      >
        <FormItem
          label="Гарчиг"
          name="title"
          rules={[{ required: true, message: "Заавал оруулна уу" }]}
        >
          <Input placeholder="Нэр" />
        </FormItem>

        <FormItem label="Төлөв" name="status">
          <Select placeholder="Төлөв" onChange={value => setStatus(value)}>
            {STATUS.map(status => (
              <Select.Option key={status.value} value={status.value}>
                {status.label}
              </Select.Option>
            ))}
          </Select>
        </FormItem>

        <FormItem label="Зураг" name="image">
          <Uploader
            disabled={Boolean(image)}
            onChange={onChangeImageUrl}
            defaultFileList={image ? [image] : []}
            type="image"
          />
        </FormItem>

        <FormItem
          label="Тайлбар"
          name="description"
          rules={[{ required: true, message: "Заавал оруулна уу" }]}
        >
          <Input.TextArea autoSize={{ minRows: 4 }} placeholder="Тайлбар" />
        </FormItem>

        <FormItem name="content" label={"Агуулга"}>
          {/* <EditorCK data={content} onChange={value => setContent(value)} /> */}
          <Input.TextArea autoSize={{ minRows: 4 }} placeholder="Агуулга" />
        </FormItem>

        <Form.Item>
          <Button type="primary">
            <Link to="/news">Буцах</Link>
          </Button>
          <Button type="primary" htmlType="submit" style={{ float: "right" }}>
            Хадгалах
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default FormComponent;
