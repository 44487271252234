import { gql, useMutation, useQuery } from '@apollo/client';
import { Spin } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Form from '../components/Form';
import { mutations, queries } from '../graphql';

type Props = {
  id: string;
};

const EditFormContainer = (props: Props) => {
  const history = useHistory();
  const [editMutation, { data }] = useMutation(gql(mutations.newsEdit));

  const { id } = props;
  const detailQuery = useQuery(gql(queries.newsDetail), {
    variables: { _id: id },
    fetchPolicy: 'network-only'
  });

  const save = (variables: any) => {
    editMutation({ variables: { _id: news._id, ...variables } });
  };

  if (data) {
    history.push('/news');
  }

  if (detailQuery.loading) {
    return <Spin />;
  }

  let news;

  if (detailQuery.data) {
    news = detailQuery.data.newsDetail;
  }

  return <Form save={save} news={news} />;
};

export default EditFormContainer;
