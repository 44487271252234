import { Col, Input, Row, Select } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import router from '../../common/router';

type Props = {
  queryParams: any;
};

const Filter = (props: Props) => {
  const history = useHistory();
  const { queryParams } = props;

  const [searchValue, setSearchValue] = useState(queryParams.searchValue);

  const onChangeSearchValue = (e: any) => {
    const searchValue = e.target.value;

    setSearchValue(searchValue);
  };

  const onChangeStatus = (status: any) => {
    router.setParams(history, {
      status
    });
  };

  const onPressEnterSearchValue = (e: any) => {
    const searchValue = e.target.value;

    router.setParams(history, {
      searchValue
    });
  };

  return (
    <Row style={{ marginBottom: 20 }}>
      <Col span="5">
        <Input
          placeholder="Гарчиг болон агуулгаар хайх"
          value={searchValue}
          onPressEnter={onPressEnterSearchValue}
          onChange={onChangeSearchValue}
        />
      </Col>
      <Col span="2" offset="1">
        <Select
          defaultValue={queryParams.status}
          onChange={value => onChangeStatus(value)}
          placeholder="Төлвөөр хайх"
          allowClear={true}
        >
          <Select.Option value="active">Идэвхтэй</Select.Option>
          <Select.Option value="inactive">Идэвхгүй</Select.Option>
        </Select>
      </Col>
    </Row>
  );
};

export default Filter;
