import React from 'react';
import { mutations } from '../graphql';
import { gql, useMutation } from '@apollo/client';

import SignIn from '../components/SignIn';
import { message } from 'antd';
import { setCookie } from '../../../utils';

export type ILogin = {
  email: string;
  password: string;
};

const SignInContainer = () => {
  const [loginMutation] = useMutation(gql(mutations.login));

  const login = async (variables: ILogin) => {
    try {
      const { data } = await loginMutation({ variables });

      if (data) {
        setCookie('user-auth-token', data.loginUser);

        window.location.href = '/';
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  return <SignIn login={login} />;
};

export default SignInContainer;
