import { useQuery, gql, useMutation } from '@apollo/client';
import { message } from 'antd';
import MemberList from '../components/Members';
import { queries, mutations } from '../graphql';

type Props = {
  queryParams: any;
};

export type IMemberDoc = {
  _id: string;
  status?: string;
  expiryDate?: number;
};

const MemberListContainer = (props: Props) => {
  const { queryParams } = props;
  const { perPage, page } = queryParams;

  const variables = {
    ...queryParams,
    perPage: perPage ? parseInt(perPage) : 15,
    page: page ? parseInt(page) : 1
  };

  const { data, error, loading, refetch } = useQuery(gql(queries.members), {
    variables,
    fetchPolicy: 'network-only'
  });
  const [editMutation] = useMutation(gql(mutations.editMember), {});

  const save = async (variables: IMemberDoc) => {
    try {
      const { data } = await editMutation({ variables });

      if (data) {
        message.success('Амжилттай солигдлоо');

        refetch();
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  if (error) {
    return null;
  }

  let members = [];
  let totalCount = 0;

  if (data) {
    members = data.members.list;
    totalCount = data.members.totalCount;
  }

  const callback = () => {
    refetch(variables);
  };

  return (
    <MemberList
      callback={callback}
      queryParams={queryParams}
      loading={loading}
      members={members}
      totalCount={totalCount}
      save={save}
    />
  );
};

export default MemberListContainer;
