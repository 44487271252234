import { Button, Card, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import PageForm from './PageForm';

type Props = {
  save: (variables: any) => void;
  code: string;
  page: any;
};

export default function Page(props: Props) {
  const { code, save, page } = props;

  const [contents, setContents] = useState(page.contents || []);

  const limit = code === 'terms-of-service' ? 1 : 10;

  useEffect(() => {
    const contents = [];
    const pageContents = page.contents || [];

    for (let i = 0; i < limit; i++) {
      if (pageContents.length > i) {
        const p = pageContents[i];

        contents.push({ _id: p._id, title: p.title, content: p.content });
      } else {
        contents.push({
          _id: Math.random().toString(),
          title: '',
          content: ''
        });
      }
    }

    setContents(contents);
  }, [page]);

  const title =
    code === 'terms-of-service' ? 'Үйлчилгээний нөхцөл' : 'Aпп ашиглах заавар';

  const changeSetContents = (_id: string, type: string, value: string) => {
    const content = contents.find(c => c._id === _id);
    content[type] = value;

    setContents(contents);
  };

  const onSave = () => {
    save({ contents: contents.filter(c => c.title || c.content) });
  };

  return (
    <Card title={title}>
      <form>
        {contents.map((c, index) => (
          <PageForm
            setContents={changeSetContents}
            index={index}
            key={c._id}
            id={c._id}
            title={c.title}
            content={c.content}
          />
        ))}
        <div className="clearfix" />
        <Form.Item>
          <Button
            type="primary"
            onClick={onSave}
            htmlType="button"
            style={{ float: 'right' }}
          >
            Хадгалах
          </Button>
        </Form.Item>
      </form>
    </Card>
  );
}
