const mutationParamsDef = `
  $title: String,
  $content: String,
  $description: String,
  $image: FileInput
  $status: String
`;

const mutationParams = `
  title: $title
  description: $description
  content: $content
  image: $image
  status: $status
`;

const newsAdd = `
    mutation newsAdd(${mutationParamsDef}) {
      newsAdd(${mutationParams}) {
        _id
      }
    }
`;

const newsEdit = `
    mutation newsEdit($_id: String! ${mutationParamsDef}) {
      newsEdit(_id: $_id ${mutationParams}) {
        _id
      }
    }
`;

const newsRemove = `
    mutation newsRemove($_id: String!) {
      newsRemove(_id: $_id)
    }
`;

export default {
  newsAdd,
  newsEdit,
  newsRemove,
};
