import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Form from '../components/Form';
import { mutations } from '../graphql';

const AddFormContainer = () => {
  const history = useHistory();
  const [addMutation, { data }] = useMutation(gql(mutations.newsAdd));

  const save = (variables: any) => {
    addMutation({ variables });
  };

  if (data) {
    history.push('/news');
  }

  return <Form save={save} />;
};

export default AddFormContainer;
