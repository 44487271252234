import React from 'react';
import { Form, Input, Card, Button } from 'antd';
import { ILogin } from '../containers/SignIn';

const FormItem = Form.Item;

type Props = {
  login: (doc: ILogin) => void;
}

const SignIn = (props: Props) => {
  const [form] = Form.useForm();

  const { login } = props;
  
  const onFinish = (values: any) => {
    const email = values.email;
    const password = values.password;

    login({ email, password });
  }

  const renderLogin = () => {
    return (
      <Form name="basic" onFinish={onFinish} form={form}>
        <FormItem name="email" rules={[
              { required: true, message: 'Имэйл хаягаа оруулна уу' }
            ]
          }>
            <Input placeholder="Имэйл" />
        </FormItem>

        <FormItem name="password"
            rules={[
              { required: true, message: "Нууц үг оруулна уу" }
            ]}>
            <Input.Password
              placeholder="Нууц үг"
            />
        </FormItem>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Нэвтрэх
          </Button>
        </Form.Item>
      </Form>
    );
  }

    return (
      <div className="center-content">
        <Card className="login-card" bordered={false}>
          {renderLogin()}
        </Card>
      </div>
    );
}
export default SignIn;
