import { Card, DatePicker, Table, Select } from 'antd';
import Paginator from '../../common/components/Paginator';
import { IMemberDoc } from '../containers/Members';
import Filter from './Filter';
import moment from 'moment';

const generateData = (list: any[]) =>
  list.map(item => ({
    key: item._id,
    _id: item._id,
    email: item.email,
    phoneNumber: item.phoneNumber,
    fullName: item.fullName,
    status: item.status,
    createdDate: item.createdDate,
    expiryDate: item.expiryDate
  }));

type Props = {
  members: any[];
  totalCount: number;
  loading: boolean;
  queryParams: any;
  callback: () => void;
  save: (variable: IMemberDoc) => void;
};

const Members = (props: Props) => {
  const { save, members, totalCount, loading, queryParams } = props;

  const data = generateData(members);

  const columns = [
    {
      title: 'Утас',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: 'Нэр',
      render: (_text: any, record: any) => <span>{record.fullName}</span>
    },
    {
      title: 'Имэйл',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Төлөв',
      render: (_text: any, record: any) => (
        <Select
          defaultValue={record.status}
          onChange={value => save({ _id: record._id, status: value })}
        >
          <Select.Option value="active">Идэвхтэй</Select.Option>
          <Select.Option value="inactive">Идэвхгүй</Select.Option>
        </Select>
      )
    },
    {
      title: 'Бүртгүүлсэн огноо',
      render: (_text: any, record: any) => (
        <span>
          {record.createdDate &&
            new Date(record.createdDate * 1000).toLocaleDateString('en-US')}
        </span>
      )
    },
    {
      title: 'Дуусах огноо',
      render: (_text: any, record: any) => (
        <span>
          <DatePicker
            style={{ width: '100%', margin: '10px 0' }}
            value={moment(record.expiryDate * 1000)}
            onChange={date => {
              if (date) {
                save({ expiryDate: date.unix(), _id: record._id });
              }
            }}
            allowClear={false}
          />
        </span>
      )
    }
  ];

  return (
    <Card title="Гишүүд">
      <Filter queryParams={queryParams} />
      <Table
        pagination={false}
        dataSource={data}
        columns={columns}
        locale={{ emptyText: 'Гишүүн олдсонгүй' }}
        loading={loading}
      />
      <Paginator total={totalCount} />
    </Card>
  );
};

export default Members;
