import React from 'react';
import MainLayout from './modules/layout/containers/MainLayout';
import AuthRoutes from './modules/auth/routes';
import MemberRoutes from './modules/member/routes';
import AppRoutes from './modules/app/routes';
import PageRoutes from './modules/page/routes';
import NewsRoutes from './modules/news/routes';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import useCurrentUser from './modules/auth/hooks/useCurrentUser';
import NotFound from './modules/layout/components/NotFound';
import ChangePassword from './modules/auth/containers/ChangePassword';

const Routes = () => {
  const { currentUser, loading } = useCurrentUser();

  if (loading) {
    return null;
  }

  if (!currentUser) {
    return <BrowserRouter>{AuthRoutes}</BrowserRouter>;
  }

  return (
    <BrowserRouter>
      <MainLayout currentUser={currentUser}>
        <Switch>
          {MemberRoutes}
          {PageRoutes}
          {AppRoutes}
          {NewsRoutes}
          <Route
            exact
            key="/changePassword"
            path="/changePassword"
            component={ChangePassword}
          />
          <Route component={NotFound} />
        </Switch>
      </MainLayout>
    </BrowserRouter>
  );
};

export default Routes;
