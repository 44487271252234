import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { parse } from 'query-string';
import Members from './containers/Members';

const index = () => {
  return <Redirect to="/members" />;
};

const members = ({ location }: { location: any }) => {
  const queryParams = parse(location.search);

  return <Members queryParams={queryParams} />;
};

export default [
  <Route exact key="index" path="/" component={index} />,
  <Route exact key="members" path="/members" render={members} />
];
