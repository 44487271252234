const commonFields = `
    _id
    title
    description
    content
    status
    image {
        name
        url
    }
    createdDate
`;

const newsList = `
    query newsList($searchValue: String, $status: String, $perPage: Int, $page: Int) {
        newsList(searchValue: $searchValue, status: $status, perPage: $perPage, page: $page) {
            list {
                ${commonFields}
            }
            totalCount
        }
    }
`;

const newsDetail = `
    query newsDetail($_id: String!) {
        newsDetail(_id: $_id) {
                ${commonFields}
        }
    }
`;

export default {
  newsList,
  newsDetail,
};
