import { Card, Table, Select, Modal, Button, Space } from 'antd';
import Paginator from '../../common/components/Paginator';
import { INewsDoc } from '../containers/List';
import Filter from './Filter';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const confirm = Modal.confirm;

const generateData = (list: any[]) =>
  list.map(item => ({
    key: item._id,
    _id: item._id,
    title: item.title,
    status: item.status,
    createdDate: item.createdDate
  }));

type Props = {
  newsList: INewsDoc[];
  totalCount: number;
  loading: boolean;
  queryParams: any;
  callback: () => void;
  save: (variable: any) => void;
  remove: (_id: string) => void;
};

const List = (props: Props) => {
  const { save, newsList, totalCount, loading, queryParams, remove } = props;

  const data = generateData(newsList);

  const showConfirm = (_id: string) => {
    confirm({
      title: 'Та устгах уу?',
      okText: 'Устгах',
      cancelText: 'Болих',
      onOk() {
        remove(_id);
      }
    });
  };

  const columns = [
    {
      title: 'Гарчиг',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Төлөв',
      render: (_text: any, record: any) => (
        <Select
          defaultValue={record.status}
          onChange={value => save({ _id: record._id, status: value })}
        >
          <Select.Option value="active">Идэвхтэй</Select.Option>
          <Select.Option value="inactive">Идэвхгүй</Select.Option>
        </Select>
      )
    },
    {
      title: 'Огноо',
      render: (_text: any, record: any) => (
        <span>
          {record.createdDate &&
            new Date(record.createdDate * 1000).toLocaleDateString('en-US')}
        </span>
      )
    },
    {
      title: '',
      render: (_text: any, record: any) => (
        <Space size="middle">
          <Link to={`/news/edit/${record._id}`}>
            <EditOutlined />
          </Link>
          <DeleteOutlined onClick={() => showConfirm(record._id)} />
        </Space>
      )
    }
  ];

  const extra = (
    <Button type="primary">
      <Link to="/news/add">Мэдээ нэмэх</Link>
    </Button>
  );

  return (
    <Card title="Мэдээ" extra={extra}>
      <Filter queryParams={queryParams} />
      <Table
        pagination={false}
        dataSource={data}
        columns={columns}
        locale={{ emptyText: 'Мэдээ олдсонгүй' }}
        loading={loading}
      />
      <Paginator total={totalCount} />
    </Card>
  );
};

export default List;
