import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Row, Col } from 'antd';
import SignIn from './containers/SignIn';
import ChangePassword from './containers/ChangePassword';

const index = () => {
  return <Redirect to="/login" />;
};

export default (
  <Row justify="center">
    <Col span={6}>
      <Route key="/login" path="/login" component={SignIn} />
      <Route key="/" path="/" component={index} />
    </Col>
  </Row>
);
