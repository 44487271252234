import { gql, useMutation } from '@apollo/client';
import { message } from 'antd';
import React from 'react';
import ChangePassword from '../components/ChangePassword';
import { mutations } from '../graphql';

const ChangePasswordContainer = () => {
  const [changePasswordMutation] = useMutation(gql(mutations.changePassword));

  const changePassword = async (variables: any) => {
    try {
      const { data } = await changePasswordMutation({ variables });

      if (data) {
        message.success('Амжилттай өөрчлөгдлөө.');
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  return <ChangePassword changePassword={changePassword} />;
};

export default ChangePasswordContainer;
