import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { parse } from 'query-string';
import List from './containers/List';
import AddForm from './containers/AddForm';
import EditForm from './containers/EditForm';

const news = ({ location }: { location: any }) => {
  const queryParams = parse(location.search);

  return <List queryParams={queryParams} />;
};

const editForm = ({ match }: any) => {
  const id = match.params._id;

  return <EditForm id={id} />;
};

export default [
  <Route exact key="news" path="/news" render={news} />,
  <Route exact key="newsAdd" path="/news/add" component={AddForm} />,
  <Route exact key="newsEdit" path="/news/edit/:_id" render={editForm} />
];
