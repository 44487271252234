import { Button, Card, Form, Input } from 'antd';
import React from 'react';

const FormItem = Form.Item;

type Props = {
  save: (variables: any) => void;
  app: any;
};

export default function Page(props: Props) {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    props.save(values);
  };

  const formItemLayout = {
    labelCol: {
      sm: {
        span: 4
      }
    }
  };

  const app = props.app || {};

  const initialValues = {
    iosVersion: app.iosVersion,
    androidVersion: app.androidVersion
  };

  return (
    <Card title="Апп мэдээлэл">
      <Form
        {...formItemLayout}
        onFinish={onFinish}
        initialValues={initialValues}
        form={form}
        style={{ maxWidth: '800px' }}
      >
        <FormItem
          label="IOS version"
          name="iosVersion"
          rules={[{ required: true, message: 'Заавал оруулна уу' }]}
        >
          <Input placeholder="IOS version" />
        </FormItem>

        <FormItem
          label="Android version"
          name="androidVersion"
          rules={[{ required: true, message: 'Заавал оруулна уу' }]}
        >
          <Input placeholder="Android version" />
        </FormItem>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
            Хадгалах
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
