import { gql, useMutation, useQuery } from '@apollo/client';
import { message, Spin } from 'antd';
import React from 'react';
import AppForm from '../components/AppForm';

type Props = {
  code: string;
};

const appSave = `
    mutation appSave($iosVersion: String, $androidVersion: String) {
        appSave(iosVersion: $iosVersion, androidVersion: $androidVersion)
    }
`;

const appInfo = `
    query appInfo {
        appInfo {
          iosVersion
          androidVersion
        }
    }
`;

export default function AppFormContainer({ code }: Props) {
  const [mutation] = useMutation(gql(appSave));
  const { data, loading } = useQuery(gql(appInfo));

  const save = async (variables: any) => {
    try {
      const response = await mutation({ variables });

      if (response.data) {
        message.success('Амжилттай хадгалагдлаа.');
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  if (loading) {
    return <Spin />;
  }

  return <AppForm save={save} app={data.appInfo} />;
}
