import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

type Props = {
  data?: string;
  onChange: (data: string) => void;
};

const EditorCK = (props: Props) => {
  const { data, onChange } = props;

  return (
    <CKEditor
      editor={ClassicEditor}
      data={data}
      config={{
        toolbar: ['bold', 'italic', 'undo', 'redo', 'link']
      }}
      onChange={(_event: any, editor: any) => {
        const data = editor.getData();

        onChange(data);
      }}
    />
  );
};

export default EditorCK;
