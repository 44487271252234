const editMember = `
    mutation editMember($_id: String!, $status: String, $expiryDate: Float) {
      editMember(_id: $_id, status: $status, expiryDate: $expiryDate) {
        status
        statusMessage
      }
    }
`;

export default {
  editMember
};
