import React from 'react';
import { Button, Dropdown, Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { UpOutlined } from '@ant-design/icons';

const { Sider, Content } = Layout;

type Props = {
  logout: () => void;
  children: React.ReactNode;
  currentUser: any;
};

const MainLayout = (props: Props) => {
  const { logout, currentUser, children } = props;

  const menu = (
    <Menu>
      <Menu.Item key="changePassword">
        <Link to="/changePassword">Нууц үг солих</Link>
      </Menu.Item>
      <Menu.Item key="logout" onClick={logout}>
        Гарах
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className="layout">
      <Sider className="siderWrapper">
        <div className="top"></div>
        <Menu theme="dark" mode="inline">
          <Menu.Item key="/members">
            <Link to="/members">Хэрэглэгчид</Link>
          </Menu.Item>
          <Menu.Item key="/news">
            <Link to="/news">Мэдээ</Link>
          </Menu.Item>
          <Menu.Item key="/page/terms-of-service">
            <Link to="/page/terms-of-service">Үйлчилгээний нөхцөл</Link>
          </Menu.Item>
          <Menu.Item key="/page/app-use-instruction">
            <Link to="/page/app-use-instruction">Апп ашиглах заавар</Link>
          </Menu.Item>
          <Menu.Item key="/app">
            <Link to="/app">Апп мэдээлэл</Link>
          </Menu.Item>
        </Menu>
        <div className="bottom">
          <Dropdown placement="topLeft" overlay={menu}>
            <Button>
              {currentUser.email} <UpOutlined />
            </Button>
          </Dropdown>
        </div>
      </Sider>
      <Content className="contentWrapper">{children}</Content>
    </Layout>
  );
};

export default MainLayout;
