import { useQuery, gql, useMutation } from '@apollo/client';
import { message } from 'antd';
import List from '../components/List';
import { queries, mutations } from '../graphql';

type Props = {
  queryParams: any;
};

export type INewsDoc = {
  _id: string;
  title: string;
  content?: string;
  imageUrl?: string;
  status?: string;
};

const ListContainer = (props: Props) => {
  const { queryParams } = props;
  const { perPage, page } = queryParams;

  const variables = {
    ...queryParams,
    perPage: perPage ? parseInt(perPage) : 15,
    page: page ? parseInt(page) : 1
  };

  const { data, error, loading, refetch } = useQuery(gql(queries.newsList), {
    variables,
    fetchPolicy: 'network-only'
  });

  const [editMutation] = useMutation(gql(mutations.newsEdit), {});
  const [removeMutation] = useMutation(gql(mutations.newsRemove), {});

  const save = async (variables: INewsDoc) => {
    try {
      const { data } = await editMutation({ variables });

      if (data) {
        message.success('Амжилттай солигдлоо');

        refetch();
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  const remove = async (_id: string) => {
    try {
      const { data } = await removeMutation({ variables: { _id } });

      if (data) {
        message.success('Амжилттай устгагдлаа');

        refetch();
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  if (error) {
    return null;
  }

  let newsList = [];
  let totalCount = 0;

  if (data) {
    newsList = data.newsList.list;
    totalCount = data.newsList.totalCount;
  }

  const callback = () => {
    refetch(variables);
  };

  return (
    <List
      callback={callback}
      queryParams={queryParams}
      loading={loading}
      newsList={newsList}
      totalCount={totalCount}
      save={save}
      remove={remove}
    />
  );
};

export default ListContainer;
