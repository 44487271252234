import { Button, Card, Form, Input } from 'antd';
import React from 'react';

const FormItem = Form.Item;

type IProps = {
  changePassword: (variables: any) => void;
};

const ChangePassword = (props: IProps) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    props.changePassword(values);
  };

  return (
    <Card title="Нууц үг солих">
      <Form onFinish={onFinish} form={form} style={{ maxWidth: '800px' }}>
        <FormItem
          label="Одоогийн нууц үг"
          name="currentPassword"
          rules={[{ required: true, message: 'Одоогийн нууц үгээ оруулна уу' }]}
        >
          <Input.Password placeholder="Одоогийн нууц үг" />
        </FormItem>
        <FormItem
          label="Шинэ нууц үг"
          name="newPassword"
          rules={[{ required: true, message: 'Шинэ нууц үгээ оруулна уу' }]}
        >
          <Input.Password placeholder="Шинэ нууц үг" />
        </FormItem>
        <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
          Өөрчлөх
        </Button>
      </Form>
    </Card>
  );
};

export default ChangePassword;
