import React from 'react';
import { Route } from 'react-router-dom';
import Page from './containers/Page';

const page = ({ match }: { match: any }) => {
  const code = match.params.code;

  return <Page code={code} />;
};

export default [<Route exact key="page" path="/page/:code" render={page} />];
