import { gql, useMutation, useQuery } from '@apollo/client';
import { message, Spin } from 'antd';
import React from 'react';
import Page from '../components/Page';

type Props = {
  code: string;
};

const pageSave = `
    mutation pageSave($code: String, $contents: [PageInput]) {
        pageSave(code: $code, contents: $contents)
    }
`;

const pages = `
    query pages($code: String) {
        pages(code: $code) {
          code
          contents
        }
    }
`;

export default function PageContainer({ code }: Props) {
  const [mutation] = useMutation(gql(pageSave));
  const { data, loading } = useQuery(gql(pages), { variables: { code } });

  const save = async (args: any) => {
    try {
      const response = await mutation({ variables: { code, ...args } });

      if (response.data) {
        message.success('Амжилттай хадгалагдлаа.');
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  if (loading) {
    return <Spin />;
  }

  return <Page save={save} code={code} page={data.pages} />;
}
