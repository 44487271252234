import React, { useState } from 'react';
import { message, Upload, Button, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { REACT_APP_API_URL } = process.env;
const confirm = Modal.confirm;

type Props = {
  defaultFileList?: any[];
  multiple?: boolean;
  type: string;
  onChange: (fileList: any[]) => void;
  disabled?: boolean;
};

const Uploader = (props: Props) => {
  const { multiple, onChange, disabled } = props;

  const getUrl = (file) => {
    if (file.url) {
      return file.url;
    }

    return 'https://myfin-public.sgp1.cdn.digitaloceanspaces.com/' + (file.response || {}).fileName;
  };

  const defaultFileList = (props.defaultFileList || []).map((file, index) => ({
    uid: index,
    ...file,
  }));

  const [fileList, setFileList] = useState(defaultFileList);

  const onPreview = (file: any) => {
    window.open(getUrl(file), '__blank');
  };

  const onChangeUpload = (e: any) => {
    const { file, fileList } = e;

    if (file.status === 'error') {
      const remaindedFileList = fileList.filter((f: any) => f.uid !== file.uid);

      setFileList(remaindedFileList);
      onChange(remaindedFileList);

      return message.error(`${file.response.fileName}`);
    }

    if (file.status === 'removed') {
      const url = file.url || (file.response || {}).fileName;

      const urlArray = url.split('/');

      // checking whether url is full path or just file name
      const fileName = urlArray.length === 1 ? url : urlArray[urlArray.length - 1];

      confirm({
        title: 'Та устгах уу?',
        okText: 'Устгах',
        cancelText: 'Болих',
        onOk() {
          fetch(`${REACT_APP_API_URL}/delete-file`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            body: `fileName=${fileName}&isPublic=true`,
            credentials: 'include',
          }).then((response) => {
            response
              .text()
              .then((text) => {
                if (!response.ok) {
                  return message.error(text);
                }

                setFileList(fileList);
                onChange(fileList);

                return message.success('Амжилттай устгалаа.');
              })
              .catch((error) => {
                return message.error(`Устгах үед алдаа гарлаа. ${error}`);
              });
          });
        },
      });
    }

    if (fileList.length > 0) {
      if (multiple) {
        setFileList(fileList);

        return onChange(
          fileList
            .filter((f: any) => f.status !== 'uploading')
            .map((f: any) => ({ name: f.name, url: f.response || f.url }))
        );
      }

      setFileList([file]);

      if (file.status !== 'uploading') {
        return onChange([{ name: file.name, url: getUrl(file) }]);
      }
    }

    return null;
  };

  const uploadProps = {
    withCredentials: true,
    action: `${REACT_APP_API_URL}/upload-file?isPublic=true`,
    onChange: onChangeUpload,
    onPreview,
    fileList,
  };

  return (
    <Upload {...uploadProps}>
      <Button disabled={disabled}>
        Файл хуулах <UploadOutlined />
      </Button>
    </Upload>
  );
};

export default Uploader;
