const commonFields = `
    _id
    email
    phoneNumber
    fullName
    status
    createdDate
    expiryDate
`;

const members = `
    query members($searchValue: String, $expiryStatus: String, $status: String, $perPage: Int, $page: Int) {
        members(searchValue: $searchValue, expiryStatus: $expiryStatus, status: $status, perPage: $perPage, page: $page) {
            list {
                ${commonFields}
            }
            totalCount
        }
    }
`;

export default {
  members
};
