import React from 'react';
import { Pagination } from 'antd';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import router from '../router';

type Props = {
  total: number,
  paramPrefix?: string
};

const Paginator = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  
  const { total } = props;
  
  const onChange = (current: any, pageSize: any) => {
    router.setParams(history, {
      [pageParamName()]: current,
      [perPageParamName()]: pageSize
    });
  }

  const pageParamName = () => {
    return `${props.paramPrefix || ''}page`;
  }

  const perPageParamName = () =>  {
    return `${props.paramPrefix || ''}perPage`;
  }

  const queryParams = queryString.parse(location.search);

  let perPage: any = queryParams[perPageParamName()];
  let page: any = queryParams[pageParamName()];

  perPage = perPage ? parseInt(perPage, 10) : 15;
  page = page ? parseInt(page, 10) : 1;

  return (
    <Pagination
      className="paginate"
      showSizeChanger
      showQuickJumper
      showTotal={(total, range) =>
        `${range[0]}-${range[1]} of ${total} items`
      }
      onChange={(current, pageSize) => onChange(current, pageSize)}
      onShowSizeChange={(current, pageSize) =>
        onChange(current, pageSize)
      }
      pageSizeOptions={[
        '15',
        '20',
        '30',
        '50',
        '100',
        '200',
        '500',
        '1000',
        '5000'
      ]}
      current={page}
      pageSize={perPage}
      total={total}
    />
  );
}

export default Paginator;
