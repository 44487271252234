import React from 'react';
import { render } from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { client } from './apolloClient';
import Routes from './routes';
import 'antd/dist/antd.css';

render(
  <ApolloProvider client={client}>
    <Routes />
  </ApolloProvider>,
  document.getElementById('root')
);
