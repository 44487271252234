import { Form, Input } from 'antd';
import EditorCK from '../../common/components/EditorCK';

const FormItem = Form.Item;

type Props = {
  content: string;
  id: string;
  title: string;
  index: number;
  setContents: (id: string, type: string, value: string) => void;
};

export default function PageForm({
  id,
  index,
  title,
  content,
  setContents
}: Props) {
  return (
    <div style={{ float: 'left', width: '40%', marginRight: '10%' }}>
      <FormItem label={`Гарчиг ${index + 1}`}>
        <Input
          defaultValue={title}
          onChange={e => setContents(id, 'title', e.target.value)}
          placeholder="Гарчиг"
          autoComplete="off"
        />
      </FormItem>

      <FormItem label={`Агуулга ${index + 1}`}>
        <EditorCK
          data={content}
          onChange={value => setContents(id, 'content', value)}
        />
      </FormItem>
    </div>
  );
}
