const login = `
    mutation loginUser($email: String, $password: String) {
      loginUser(email: $email, password: $password)
    }
`;

const changePassword = `
    mutation usersChangePassword($currentPassword: String, $newPassword: String) {
        usersChangePassword(currentPassword: $currentPassword, newPassword: $newPassword)
    }
`;

export default {
  login,
  changePassword
};
